import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './ApiData.css'; // Import the CSS file for styling

const ApiData = () => {
  const [data, setData] = useState([]);
  const [sortBy, setSortBy] = useState('first_name'); // Default sorting by first name
  const [filter, setFilter] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Replace with your API endpoint URL
        const apiUrl = 'https://mzuoapi.mwanzauniversity.ac.tz:5001/fetch_data';
        const response = await axios.get(apiUrl);
        setData(response.data); // Assuming the API returns an array of objects
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const sortData = (data, sortBy) => {
    return [...data].sort((a, b) => a[sortBy].localeCompare(b[sortBy]));
  };

  const filterData = (data, filter) => {
    return data.filter(item => {
      return (
        item.first_name.toLowerCase().includes(filter.toLowerCase()) ||
        item.last_name.toLowerCase().includes(filter.toLowerCase()) ||
        item.citizenship.toLowerCase().includes(filter.toLowerCase())
      );
    });
  };

  const sortedData = sortData(data, sortBy);
  const filteredData = filterData(sortedData, filter);

  return (
    <div>
      <h1>Applicants Data</h1>
      <div className="filter">
        <label htmlFor="filter">Filter:</label>
        <input
          type="text"
          id="filter"
          value={filter}
          onChange={e => setFilter(e.target.value)}
        />
      </div>
      <table>
        <thead>
          <tr>
            <th onClick={() => setSortBy('first_name')}>First Name</th>
            <th onClick={() => setSortBy('last_name')}>Last Name</th>
            <th onClick={() => setSortBy('citizenship')}>Citizenship</th>
            <th>Date of Birth</th>
            <th>Education Background</th>
            <th>Work Experience</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((item, index) => (
            <tr key={index} className={index % 2 === 0 ? 'odd' : 'even'}>
              <td>{item.first_name}</td>
              <td>{item.last_name}</td>
              <td>{item.citizenship}</td>
              <td>
                <DOBInput dob={item.dob} />
              </td>
              <td>{item.education_background}</td>
              <td>{item.work_experience}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const DOBInput = ({ dob }) => {
  const date = new Date(dob);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  return (
    <div>
      <span>{day}/{month}/{year}</span>
    </div>
  );
};

export default ApiData;
