import React, { useRef, useState } from 'react';
import ApiData from './ApiData';
import { useReactToPrint } from 'react-to-print';

function ExportToPdf() {
  const [isButtonVisible] = useState(false);
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div>
       {isButtonVisible && (
        <button onClick={handlePrint}>Export to PDF</button>
      )}
      <ApiData ref={componentRef} />
    </div>
  );
}

export default ExportToPdf;
