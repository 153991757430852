// Import the ExportToPdf component
import ExportToPdf from './ExportToPdf';

function App() {
  return (
    <div>
      {/* Other components or content */}
      <ExportToPdf />
      {/* Other components or content */}
    </div>
  );
}

export default App;